import React from "react"

import { withFlexible } from "./withFlexible"
import { Wrapper, Title } from "./FlexibleStyles"
import { Sections } from "../Sections/Sections"

export const Flexible = withFlexible(({ title, page, hideTitle, noTopPadding, noBottomPadding }) => (
  <Wrapper hideTitle={hideTitle} noTopPadding={noTopPadding} noBottomPadding={noBottomPadding}>
    {hideTitle !== "hide" ? <Title>{title}</Title> : null}
    <Sections page={page} fullPage />
  </Wrapper>
))
