import tw, { styled, css } from "twin.macro"

export const Wrapper = styled.div<WrapperProps>`
  ${css`
    minheight: 100vh;
  `}
  ${({ hideTitle, noBottomPadding, noTopPadding }) => {
    if (hideTitle === "hide") {
      if (noBottomPadding && noTopPadding) {
        return tw``
      } else if (noBottomPadding) {
        return tw`pt-2-8 lg:pt-2 md:pt-1`
      } else if (noTopPadding) {
        return tw`pb-6`
      } else {
        return tw`pt-2-8 pb-6 lg:pt-2 md:pt-1`
      }
    } else {
      if (noBottomPadding && noTopPadding) {
        return tw``
      } else if (noBottomPadding) {
        return tw`pt-7 md:pt-12-4`
      } else if (noTopPadding) {
        return tw`pb-8 md:pb-12`
      } else {
        return tw`pt-7 md:pt-12-4 pb-8 md:pb-12`
      }
    }
  }}
`

export const Title = tw.h1`
  text-35 leading-1.28 px-2 tracking-3 md:text-60 md:leading-none tracking-2 font-bold uppercase mb-8 text-center
`

type WrapperProps = {
  hideTitle?: "hide" | "show" | null
}
